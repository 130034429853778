import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Checkbox, FormControlLabel, Link, TextField} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import {deepCopy, helperCatchErrors, inputOnlyInteger} from "../../../helper/Helper";
import ImageUpload from "../../imageUpload/ImageUpload";
import Notifications from "../../notifications/Notifications";
import {useIntl} from "react-intl";
import config from "../../../config/config";
import axios from "axios";
import {useStore} from "../../../store/useStore";

const VideoElement = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);
    const videoRef = useRef();
    const [cancelToken] = useState(axios.CancelToken.source());

    const [width, setWidth] = useState(props.width || '');
    const [thumb, setThumb] = useState(props.thumb || '');
    const [deactivateControls, setDeactivateControls] = useState(props.deactivateControls);
    const [video, setVideo] = useState('');
    const [videoUpload, setVideoUpload] = useState(false);
    const [videoOriginal] = useState(props.src || '');
    const [removeAmount, setRemoveAmount] = useState(0);
    const [removeCounter, setRemoveCounter] = useState(0);
    const [removeId, setRemoveId] = useState('');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const prepareTranslationUploadRefs = () => {
        const refs = {};

        if (state.languages.length) {
            state.languages.forEach(language => {
                refs[language['@id']] = {
                    ref: createRef(),
                    uploaded: false
                };
            });
        }

        return refs;
    }

    const [uploadRefs] = React.useState(prepareTranslationUploadRefs());

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }

    const initSaveModal = () => {
        setNotificationError(false);

        if (!props.src && !videoRef.current.hasFile()) {
            showError(GlobalTransIntl('missing_video', intl));
            return;
        }

        videoRef.current.sendFile();

        Object.keys(uploadRefs).forEach(refKey => {
            uploadRefs[refKey].ref.current.sendFile();
        });
    }

    const saveModal = useCallback(() => {
        // create data
        const data = {
            src: video,
            thumb,
            width,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, thumb, width, translations, video]);

    useEffect(() => {
        if (videoUpload && !Object.keys(uploadRefs).find(refKey => uploadRefs[refKey].uploaded === false)) {
            setVideoUpload(false);
            saveModal();
        }
    }, [videoUpload, saveModal, uploadRefs]);

    const setTranslationUpload = (languageIRI, value) => {
        setTranslations(prev => {
            const newPrev = deepCopy(prev);
            const findTranslation = newPrev.find(translation => translation.language === languageIRI);

            if (findTranslation) {
                findTranslation.src = value;
            } else {
                newPrev.push({
                    language: languageIRI,
                    src: value,
                });
            }

            return newPrev;
        });
    }

    const getTranslationOriginal = (language, getIRI = true) => {
        const translation = translations.find(translation => translation.language === language['@id']);

        if (translation && translation?.src?.['@id']) {
            if (getIRI) {
                return translation?.src['@id'];
            }

            return translation.src;
        }

        return null;
    }

    const renderImageUploads = () => {
        return (
            <>
                <Box display={!translationLanguage ? 'block' : 'none'}>
                    <ImageUpload
                        ref={videoRef}
                        setImageIRI={setVideo}
                        showError={showError}
                        catchErrors={catchErrors}
                        setUploadStatus={setVideoUpload}
                        originalIRI={videoOriginal?.['@id']}
                        filetypes={['video/*']}
                        returnType={'object'}
                    />
                </Box>
                {
                    (Object.keys(uploadRefs).length > 0) &&
                    state.languages.map((language) => (
                        <Box display={translationLanguage === language['@id'] ? 'block' : 'none'} key={language['@id']}>
                            <ImageUpload
                                ref={uploadRefs[language['@id']].ref}
                                setImageIRI={(value) => setTranslationUpload(language['@id'], value)}
                                showError={showError}
                                catchErrors={catchErrors}
                                setUploadStatus={(value) => {
                                    uploadRefs[language['@id']].uploaded = value;
                                }}
                                originalIRI={getTranslationOriginal(language)}
                                filetypes={['video/*']}
                                returnType={'object'}
                            />
                            {
                                (getTranslationOriginal(language)) &&
                                <Box mt={1}>
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href={config.apiHost + getTranslationOriginal(language, false).contentUrl}
                                    >
                                        {getTranslationOriginal(language, false).contentUrl}
                                    </Link>
                                </Box>
                            }
                        </Box>
                    ))
                }
            </>
        );
    }

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                {renderImageUploads()}
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('thumbnail')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               setThumb(e.target.value)
                           }}
                           value={thumb}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('width')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => inputOnlyInteger(e, setWidth)}
                           value={width}
                />
            </Box>
            <Box mb={2}>
                <FormControlLabel
                    control={<Checkbox checked={deactivateControls}
                                       onChange={() => setDeactivateControls(prev => !prev)}
                                       name={GlobalTransIntl('deactivate_controls', intl)}
                                       id={'dimensions-create--is-hidden'}/>}
                    label={GlobalTransIntl('deactivate_controls', intl)}
                />
            </Box>
        </React.Fragment>
    );

    useEffect(() => {
        if (removeAmount !== 0 && removeCounter === removeAmount) {
            props.removeContent(removeId);
        }
    }, [removeAmount, removeCounter, removeId, props]);

    const increaseRemoveCounter = () => {
        setRemoveCounter(prev => prev + 1);
    }

    const axiosDeleteVideo = (IRI) => {
        axios.delete(config.apiHost + IRI, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(() => increaseRemoveCounter());
    }

    const removeFunction = (_props) => {
        if (props.src) {
            setRemoveAmount(translations.length + 1);
            setRemoveId(_props);

            axiosDeleteVideo(props.src['@id']);

            if (translations.length) {
                translations.forEach(translation => {
                    if (translation?.src?.['@id']) {
                        axiosDeleteVideo(translation.src['@id']);
                    }
                });
            }
        } else {
            props.removeContent(_props)
        }
    }

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={initSaveModal}
                removeElementFunction={removeFunction}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            <Notifications
                error={notificationError}
                errorMessage={errorMessage}
            />
            {
                (props.src?.contentUrl) &&
                <video src={config.apiHost + props.src.contentUrl} width={props.width} poster={props.thumb} controls>
                    {GlobalTransIntl('browser_doesnt_support_video', intl)}
                </video>
            }
        </Box>
    );
};

VideoElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const VideoElementConfig = {
    title: 'video',
    src: '',
    thumb: '',
    width: '',
    deactivateControls: false,
    translations: [],
}

export default VideoElement;